<template>
    <div class="base">
        <main class="main-body">
            <div class="security-body" id="resetPW-body">
                <router-link to="/login">
                  <div class="text-center">
                    <img :src="require('../assets/images/horizontal_logo.png')" width="250" height="50">
                  </div>        
                </router-link>

                <div class="text-center mt-3 mb-2 title">{{$t("RESET PASSWORD")}}</div>

                <form id="resetpassword" @submit.stop.prevent="resetPassword">
                    <div class="form-group">
                        <label for="email">{{$t("Email")}}</label>
                        <input type="text" class="form-control" id="email" :placeholder="$t(`Enter Email`)" v-model="email" readonly>
                    </div>

                    <div class="form-group">
                        <label for="password">{{$t("Password")}}</label>
                          <b-form-input
                            :type="showPassword ? 'text' : 'password'"
                            id="example-input-1"
                            :placeholder="$t(`Enter password`)"
                            name="example-input-1"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <label for="confirmPW">{{$t("Confirm Password")}}</label>
                          <b-form-input
                            :type="showPassword ? 'text' : 'password'"
                            id="example-input-2"
                            :placeholder="$t(`Enter confirm password`)"
                            name="example-input-1"
                            v-model="$v.form.confirmPassword.$model"
                            :state="validateState('confirmPassword')"
                            aria-describedby="input-2-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button type="submit" class="security-btn">{{$t("Reset")}}</button>
                    </div>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
import { required,sameAs } from "vuelidate/lib/validators";
 
import axios from "axios";

export default {
  name:"ResetPassword",
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      email: this.$route.query.email,
      password: null,
      confirmPassword: null,
      form:{
        password:"",
        confirmPassword:""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      confirmPassword:{
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    async resetPassword(){
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
            this.isClick = false;
            return;
        }

       

        try{
          let params = {
            password:this.form.password
          };

          const baseUrl = process.env.VUE_APP_CORE_BASE_URL;
          const response = await axios.post(`${baseUrl}/operators/forget-password`,params,{
            headers:{
              Authorization: 'Bearer ' + this.$route.query.token,
            }
          });
          console.log(response);

          this.$swal({
              text: this.$t("Reset password success"),
              icon: 'success',
          });

          this.$router.push({name:"Login"});
          
        }catch(error){
          this.$swal({
              text: error.response.data.message,
              icon: 'error'
          });
        }
    }
  }
};
</script>

<style>
@import '../css/styles.css';

#resetPW-body {
  margin: 5% 10%;
  width: 40%;
}

.password-input {
  display: flex;
  position: relative;
  align-items: center;
}

.password-input button {
  position: absolute;
  right: 1%;
  display: flex;
  border: none;
  background-color: transparent;
  color: var(--info-50);
}

@media only screen and (max-width: 768px) {
  #resetPW-body {
    margin:10% 5%;
    width: 100%;
  }
}
</style>